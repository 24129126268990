<template>

	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/intro_character_2.png')" /></div>

			<div class="text_guide pt-20">
				<h3> error test</h3>
			</div>
			<div class="text_guide_desc pt-15 color-gray">

				<p class="error"> test </p>

			</div>
		</div>


		<div class="intro_btn_wrap mb-20">
			<div class="btn btn_area">
				<button class="btn_l btn_fill_blue" @click="postTest">확인</button>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'test'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'test'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
		}
	}
	, methods: {
		postTest: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_error_request
					, data: {
						http_status_code: '401'
					}
					, type: true
				})
				if(result.success){
					this.is_confirm = true
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>
<style>
</style>